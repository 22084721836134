<template>
  <div class="section1">
    <div>
      <div class="horseRaceLamp">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item v-for="item in banner" :key="item.id">
            <img
              :src="item.thumb"
              @click="goToLink(item.link)"
              :style="{
                cursor: item.link ? 'pointer' : 'normal',
              }"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="flex col">
      <div class="flex row between">
        <img src="../../../../assets/pc/images/index/titleLogo.png" alt="" />
        <div class="remenzhengce">近期热门政策</div>
        <!-- <router-link to="/policy/tongzhi" class="more">更多 ></router-link> -->
      </div>
      <!--  滚动新闻  -->

      <div
        class="outer-container"
        id="outer-container"
        ref="scroll"
        @scroll="handleScroll"
      >
        <vue-seamless-scroll
          :data="listDataCurrent"
          :class-option="classOption"
          class="warp"
        >
          <ul class="item">
            <li
              v-for="(item, index) in listDataCurrent"
              :key="index"
              class="flex col"
              style="border-bottom: 1px solid #e9eaec"
              @click="goToZC(item.id, item.zhengceid)"
            >
              <div class="flex between">
                <span class="title" v-text="item.subtitle"></span>
                <!-- <span class="hot" v-text="item.heat"></span> -->
              </div>
              <div class="flex between">
                <span class="">
                  <span style="display:none">距申报截止13天</span>
                  <span
                    v-for="(tags, index) in item.supportMode"
                    :key="index"
                    class="tags"
                    >{{ tags }}</span
                  >
                  <!-- <span class="tags">省级</span> -->
                  <!-- <span
                    v-if="item.money > 0"
                    style="
                      padding: 0 5px 0 5px;
                      line-height: 20px;
                      border: 1px solid #e5e5e5;
                      color: #8d8d8d;
                    "
                  >
                    最高{{ item.money }}万
                  </span> -->
                </span>
              </div>
              <div>
                <span>发文部门:&nbsp;&nbsp;{{ item.getFwDepartment }}</span>
                <span style="margin-left: 30px"
                  >发文时间:&nbsp;&nbsp;{{ item.DispatchDate }}</span
                >
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    <permission
      v-if="dialogVisiblePermission"
      :dialogVisiblePermission="dialogVisiblePermission"
      @handleToggle="handleToggle"
      :typeDialog="typeDialog"
      @handlePermission="handlePermission"
    >
    </permission>
  </div>
</template>

<script>
import { request } from "@/network";
import { setInfoData } from "@/assets/public/utils/token";
import permission from "@/components/pc/permission.vue";
export default {
  name: "indexSection1",
  components: {
    permission,
  },
  props: {
    listDataCurrent: Array,
    banner: Array,
  },
  data() {
    return {
      listData: [],
      dialogVisiblePermission: false,
      typeDialog: "Vip",
      timer: null,
    };
  },
  mounted() {
    // this.timer = setTimeout(() => { // 给自动滚动复制出来的dom加点击事件
    //   const arr = document.getElementsByClassName('warp')[0].children[0].children[1].children[0].children
    //   for (const key in arr) {
    //     if (!(arr[key] instanceof Function) && isNaN(arr[key])) {
    //
    //       const item = this.listDataCurrent[key]
    //       arr[key].addEventListener('click', () => {
    //         this.goToZC(item.id, item.zhengceid)
    //       })
    //     }
    //   }
    // }, 1000)
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer);
  },
  computed: {
    classOption() {
      return {
        step: 1, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    async getData1(city) {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/index/getHotPolicy",
        data: {
          city: city,
        },
      });

      if (res.code === 200) {
        this.listDataCurrent = res.data;
      }
    },
    async getData2(city) {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/index/countCodeAndParentId",
        params: {
          name: city,
        },
      });
      if (res.code === 200) {
        this.statisticsData = res.data;
      }
    },
    goToZC(xm, zc) {
      setInfoData(request).then((resLogin) => {
        if (resLogin === "islogin") {
          this.$router.push({
            path: "/formaldetails/jiedu",
            query: {
              id: zc,
              inputId: xm,
            },
          });
        } else {
          this.dialogVisiblePermission = !this.dialogVisiblePermission;
          this.typeDialog = "Login";
        }
      });
    },
    handleProMatching() {
      this.$router.push("/matching");
    },
    handleToggle() {
      this.dialogVisiblePermission = !this.dialogVisiblePermission;
    },
    handlePermission(type) {
      if (type === "Vip") {
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
        this.$message({
          message: "已向客服发送请求成为协会会员通知",
          type: "success",
        });
      } else if (type === "Login") {
        this.$router.push({ name: "login" });
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
      }
    },
    handleScroll(e) {
      if (
        e.target.scrollHeight - e.target.scrollTop <=
        e.target.scrollHeight / 2 - e.target.clientHeight
      ) {
        e.target.scrollTop = e.target.clientHeight;
      } else if (
        e.target.scrollHeight - e.target.scrollTop ===
        e.target.scrollHeight
      ) {
        e.target.scrollTop = e.target.scrollHeight / 2 - e.target.clientHeight;
      }
    },
    goToLink (link) {
      window.location.href = link;
    }
  },
};
</script>

<style lang="less" scoped>
.section1 {
  width: 1200px;
  margin: 32px auto;
  margin-bottom: 35px;
  // width: 1200px;
  height: 342px;
  // margin-bottom: 50px;
}

.section1 > div:nth-child(2) {
  // width: 730px;
  flex: 1;
  height: 342px;
  float: right;
}

.section1 .remenzhengce {
  width: 100%;
}

.section1 .more {
  width: 58px;
  font-size: 14px;
  line-height: 25px;
  color: #015293;
  text-align: right;
}

.section1 > div:nth-child(2) > div:nth-child(1) {
  // height: 40px;
  width: 100%;
  padding-bottom: 10px;
  justify-content: left;
  // border-bottom: 2px solid #015293;
  // line-height: 40px;
}

.outer-container {
  overflow: hidden;
  // overflow-y: scroll;
  width: 200px;
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
  scrollbar-width: none;
}

.section1 .warp {
  // height: 3630px;
  width: 670px;
  margin: 0 auto;
  overflow: hidden;
  // overflow-y: scroll;

  ul {
    // height: 3630px;
    padding-right: 6px;
    // background-color: #ff8198;
    overflow-y: scroll;
    list-style: none;
    padding: 0;
    margin: 0 auto;

    li,
    a {
      // height: 90px;
      padding: 10px 0;
      cursor: pointer;

      div {
        width: 100%;
        // height: 26px;
      }

      div:nth-child(1) span:nth-child(1) {
        font-size: 16px;
        width: 650px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      div:nth-child(1) span:nth-child(2) {
        font-size: 14px;
        color: #9e9e9e;
        white-space: nowrap;
      }
      div:nth-child(2) {
        margin: 10px 0;
      }
      div:nth-child(2) > span:nth-child(1) {
        height: 20px;
        line-height: 20px;
        width: 420px;
        font-size: 12px;

        span {
          margin-right: 10px;
        }
        > span:nth-child(1) {
          background-color: rgba(255, 0, 0, 0.1);
          color: rgba(255, 0, 0, 100, 0.5);
          padding: 4px 8px;
          border-radius: 4px;
        }
      }

      div:nth-child(2) > span:nth-child(2) {
        font-size: 14px;
        color: #b8b8b8;
        white-space: nowrap;
      }
      div:nth-child(3) {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

// .section1 .item{
//   height: 100%;
//   overflow: hidden;
// }
.tags {
  padding: 3px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
.zijinfuchifu {
  padding: 4px 5px;
  color: #ffffff;
  background-color: #f68621;
  // border: 1px solid #f68621;
  line-height: 20px;
}

.guquantouzhi {
  padding: 0 5px 0 5px;
  color: #ffffff;
  background-color: #1f88f9;
  border: 1px solid #1f88f9;
  line-height: 20px;
}

.chenghaorending {
  padding: 0 5px 0 5px;
  color: #ffffff;
  background-color: #2ebc66;
  border: 1px solid #2ebc66;
  line-height: 20px;
}

.jianshuijiangfei {
  padding: 0 5px 0 5px;
  color: #ffffff;
  background-color: #bf382f;
  border: 1px solid #bf382f;
  line-height: 20px;
}
.qita {
  padding: 0 5px 0 5px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 20px;
}

.jinrongfuchi {
  padding: 0 5px 0 5px;
  color: #ffffff;
  background-color: #6cc2ed;
  border: 1px solid #6cc2ed;
  line-height: 20px;
}

.section1 > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) {
  // width: 50%;
  margin-left: 10px;
  text-align: left;
  color: #101010;
  font-size: 20px;
  font-weight: bold;
}

.section1 > div:nth-child(2) > div:nth-child(2) {
  height: 360px;
  width: 100%;
}

.section1 > div:nth-child(1) {
  position: relative;
  width: 500px;
  height: 342px;
  float: left;
  .horseRaceLamp {
    width: 505px;
    height: 100%;
    div {
      height: 342px;
      border-radius: 8px;
      img {
        height: 342px;
        width: 100%;
        border-radius: 8px;
      }
    }
  }
  .horseRaceLamp .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  .horseRaceLamp .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .horseRaceLamp .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
</style>
