<template>
  <div class="section3">
    <div class="flex row between">
      <div class="flex">
        <img src="../../../../assets/pc/images/index/titleLogo.png" alt="" />
        <div class="xinwenzhongxin">新闻中心</div>
      </div>
      <router-link to="/newscenter" class="more">查看更多 ></router-link>
    </div>
    <ul class="">
      <li
        class="flex between"
        v-for="(item, index) in list"
        :key="index"
        @click="goToNewsDetail(item.id)"
      >
        <!-- <div class="icon">>></div> -->
        <div class="">{{ item.title }}</div>
        <!-- <div class="">申报通</div> -->
        <div class="">{{ item.createtime }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { request } from "@/network";
export default {
  name: "indexSection3",
  props: {
    list: Array,
  },
  data() {
    return {};
  },
  created() {
    // this.getData()
  },
  methods: {
    async getData() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/index/getNews",
        params: {
          page: 1,
          pagesize: 10,
        },
      });

      if (res.code === 200) {
        this.list = res.data;
      }
    },
    goToNewsDetail(id) {
      this.$router.push({
        path: "/newsdetail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.section3 {
  margin: 0 auto;
  width:1200px;
}
.section3 .xinwenzhongxin {
  font-size: 20px;
  font-weight: bold;
  // color: #015293;
  // height: 31px;
  text-align: left;
  margin-left: 10px;
}
.section3 .more {
  font-size: 14px;
  color: rgba(21, 110, 208, 100);
}
.section3 ul {
  padding-bottom: 136px;
  margin-top: 20px;
}
.section3 li {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid #e9eaec;
}
.section3 li:hover {
  div:nth-child(1) {
    color: #777777;
  }
}
// .section3 li div:nth-child(1) {
//   width: 30px;
//   color: #015293;
//   text-align: right;
//   font-weight: bold;
// }
.section3 li div:nth-child(1) {
  // margin-left: -40px;
  width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}
// .section3 li div:nth-child(1) {
//   width: 150px;
//   text-align: right;
//   color: #b8b8b8;
// }
.section3 li div:nth-child(2) {
  width: 100px;
  text-align: right;
  color: #b8b8b8;
}
</style>
